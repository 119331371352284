export default [
  {
    key: 'code',
    label: 'field.code',
    type: 'text',
    operator: '%',
  },
  {
    key: 'nameEn',
    label: 'field.nameEn',
    type: 'text',
    operator: '%',
  },
  {
    key: 'nameKm',
    label: 'field.nameKm',
    type: 'text',
    operator: '%',
  },
]
